<template>
  <div>
    <a-drawer :title="`${form.id ? '编辑' : '新建'}支付方式`" :width="720" :visible="visible"
      :body-style="{ paddingBottom: '80px' }" :footer-style="{ textAlign: 'right' }" @close="onClose">
      <a-form :model="form" :rules="rules" layout="vertical">
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="名称" name="name">
              <a-input v-model:value="form.name" placeholder="请输入" />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="简称" name="short_name">
              <a-input v-model:value="form.short_name" placeholder="请输入" />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="图标" name="icon">
              <UploadFile v-model:value="form.icon" />
            </a-form-item>
          </a-col>

          <a-col :span="24" v-if="form.code == 'wechat'">

            <a-form-item label="商户号" name="mch_id">
              <a-input v-model:value="configData.mch_id" placeholder="请输入" />
            </a-form-item>
            <a-form-item label="APPID(扫码支付需要)" name="app_id">
              <a-input v-model:value="configData.app_id" placeholder="请输入" />
            </a-form-item>

            <a-form-item label="API密钥" name="pay_sign_key">
              <a-input v-model:value="configData.pay_sign_key" placeholder="请输入" />
            </a-form-item>
            <a-form-item label="证书序列号" name="series_no">
              <a-input v-model:value="configData.series_no" placeholder="请输入" />
            </a-form-item>
            <a-form-item label="支付证书pem" name="apiclient_cert">
              <UploadFile v-model:value="configData.apiclient_cert" />
            </a-form-item>
            <a-form-item label="支付证书密钥" name="apiclient_key">
              <UploadFile v-model:value="configData.apiclient_key" />
            </a-form-item>
          </a-col>
          <a-col :span="24" v-if="form.code == 'alipay'">
            <a-form-item label="应用ID" name="app_id">
              <a-input v-model:value="configData.app_id" placeholder="请输入" />
            </a-form-item>
            <a-form-item label="应用私钥" name="private_key">
              <a-input v-model:value="configData.private_key" placeholder="请输入" />
            </a-form-item>
            <a-form-item label="支付宝公钥" name="ali_public_key">
              <a-input v-model:value="configData.ali_public_key" placeholder="请输入" />
            </a-form-item>

          </a-col>


          <a-col :span="24">
            <a-form-item label="排序" name="sort">
              <a-input type="number" v-model:value="form.sort" placeholder="请输入" />
            </a-form-item>
          </a-col>


          <a-col :span="24">
            <a-form-item label="是否启用" name="status">
              <a-select v-model:value="form.status" placeholder="请选择">
                <a-select-option value="1">是</a-select-option>
                <a-select-option value="0">否</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <!-- <a-col :span="12">
            <a-form-item label="分类说明" name="description">
              <a-input v-model:value="form.description" placeholder="请输入" />
            </a-form-item>
          </a-col> -->
        </a-row>
      </a-form>
      <template #extra>
        <a-space>
          <a-button @click="onClose">取消</a-button>
          <a-button :loading="createLoading" type="primary" @click="onConfirm">提交</a-button>
        </a-space>
      </template>
    </a-drawer>
  </div>
</template>
<script>
//

import service from '@/service/service';
import UploadFile from '@/components/common/uploadFile.vue';
import { message } from 'ant-design-vue';
const rules = {
  name: [{
    required: true,
    message: '请输入分类名称',
  }],
  sort_num: [{
    required: true,
    message: '请输入分类序号',
  }],
};
export default {
  components: {
     UploadFile
  },
  props: ['current'],
  data() {
    return {
      rules,
      form: {
        id: '',
        name: '',
        short_name:'',
        icon: '',
        sort: '',
        code: '',
        status:0,
        config: '',
      },
      configData:{},//支付配置参数
      visible: false,
      createLoading: false,
    }
  },
  watch: {
    current: {
      handler(nowCurrent) {
        if (nowCurrent) {
          let temp = {};
          for (let key in this.form) {
            temp[key] = nowCurrent[key];
          }
          this.form = temp;
          this.configData = JSON.parse(this.form.config)
          // console.log("bbbbbb")
          // console.log(this.configData)
        }
      },
      immediate: true
    }
  },
  methods: {
    onClose() {
      this.visible = false;
      this.onReset();
      this.$emit('close');
    },
    onReset() {
      let temp = {};
      for (let key in this.form) {
        temp[key] = '';
      }
      this.form = temp;
    },
    onShow() {
      this.visible = true;
    },
    async onConfirm() {
      this.createLoading = true;
      console.log(this.form);
      this.form.config = this.configData;

      const api = this.form.id ? 'edit_payment' : 'add_payment';
      const { code, msg } = await service[api]({
        ...this.form
      })
      this.createLoading = false;
      if (code && code == '1') {
        message.success(msg || '成功');
        this.$emit('refreshList');
        this.onClose();
      }
    }
  }
}
</script>